.mainContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0;
  padding: 40px 5%;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    margin: 0;
  }
  .warning {
    color: red;
  }
  .info {
    color: rgb(0, 110, 255);
  }
  .MuiBox-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
