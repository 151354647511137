.branchcollectedallocation {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  gap: 1rem;
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }
  .warning {
    color: red;
    font-size: 10px;
  }
}
.MuiBox-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiPaper-root.MuiPaper-elevation {
  box-shadow: 0px 3px 13px 0px rgb(0 0 0 / 17%);
}
.MuiTableRow-head {
  font-weight: 600;
  .MuiTableCell-root {
    font-weight: 600;
  }
}
