.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.header {
  background: #fff !important;
  padding: 0 !important;
}

.logo img {
  margin: 15px 30px;
}

#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: #333;
  border-radius: 6px;
  margin: 16px 28px 16px 0;
  float: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-height {
  height: 100vh !important;
}

.login-background {
  background: linear-gradient(244.84deg, #478d72 2.94%, #397788 81.1%);
}

.center-all {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  width: 320px;
  height: 280px;
  padding: 36px !important;
  background-color: #fff;
  backdrop-filter: blur(12px);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  text-align: center;
}

.login-image {
  text-align: center;
  margin: 24px;
}

.login-form button {
  width: 100%;
}

.login-form p {
  color: rgb(204, 204, 204);
  text-align: center;
  margin-top: 16px;
}

.login-form p span {
  &:first-child {
    margin-right: 16px;
  }
}

.login-logo {
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 24px;
}

.login-logo img {
  width: 40px;
  margin-right: 8px;
}

.login-logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.myhr {
  margin: 20px 0;
  background-color: #eee;
  border: 0;
  height: 1px;
}

.promos {
  margin-top: 20px;
}

.c-cards {
}

.c-cards h4 {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-cards h4 span {
  font-weight: normal;
}

.ant-card-head-title {
  width: 75% !important;
}

.c-cards .ant-card-body {
  padding: 0;
}

.c-cards .launch-btn {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 42px;
}

.custom-image {
  margin-bottom: -5px;
  display: block;
  height: 250px;
  cursor: pointer;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.no-campaign-image {
  margin: 15px auto;
  width: 80px;
  display: block;
}

.no-campaign-text {
  color: #6d6d6d;
  font-size: 18px;
  font-weight: 400;
  margin: 15px 0;
}

.campaign-container {
}

.campaign-container .header {
  text-align: left;
  padding-bottom: 30px;
}

.campaign-container .content {
  padding: 30px 0;
}

.campaign-container .content hr {
  width: 85%;
}

.campaign-container .content .factor {
  margin-bottom: 20px;
}

.campaign-container .content .factor h2 {
  font-size: 18px;
  font-weight: normal;
  color: #777474;
}

.campaign-container .header .badge {
  float: right;
}
.ant-layout-sider {
  width: 249px !important;
  flex: 0 0 249px !important;
  max-width: 249px !important;
  min-width: 249px !important;
}
