.pastPayment {
  font-size: 22px;
  font-weight: 700;
}

.done {
  color: #67c15e !important;
}

.pending,
.created,
.refunded {
  color: #48698b !important;
}

.failed {
  color: #eb3323 !important;
}

.paymentrow:hover {
  cursor: pointer;
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.8);
}
