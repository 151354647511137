.PseudoBatchEdit {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(0%, -8%);
  background: #fff;
  width: 40%;
  height: 320px;
  border: 2px solid #000;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 1rem;
  overflow: auto;
  border-radius: 8px;
}
