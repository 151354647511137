.CashPaymentsCollected {
  background: #fff;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
  }
}

.closeIcon:hover {
  cursor: pointer;
}
