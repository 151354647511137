.EditSubventionPoint {
  background: #fff;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;
}

.tooltipabc {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #055646;
  border-radius: 4px;
  padding: 7px 12px;
  max-width: 100%;
  width: fit-content;
  height: 35px;
  margin-top: 4px;
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -3px;
}

.tooltipabc .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: #222222;
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: -50%;
  margin-left: 0px;
  height: fit-content;
  white-space: normal;
  overflow-wrap: break-word;
}

.tooltipabc:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 10px;
  overflow: hidden;
}

.tooltiptext i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translate(-45%, -37%) rotate(45deg);
  background-color: #222222;
}
