.AddPayment {
  background: #fff;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
  }
  .warning {
    color: rgb(56, 121, 218);
    font-size: 10px;
  }
  .MuiTableCell-head {
    font-weight: 600;
  }
  .buttonRow {
    margin: auto 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
}
