.mainContainer {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  gap: 1rem;
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    margin: 0;
  }
  .warning {
    color: red;
  }
  .info {
    color: rgb(0, 110, 255);
  }
  .MuiBox-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pastPayment {
  font-size: 18px;
  font-weight: 700;
}

.sendLink {
  border-radius: 4px;
  background: #1876d2;
  color: white;
  padding: 10px 20px;
}

.sendLink:hover {
  cursor: pointer;
}

.done {
  color: #67c15e;
}

.pending {
  color: #48698b;
}

.failed {
  color: #eb3323;
}
