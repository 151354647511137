.EntryDelete {
  position: absolute;
  top: 20%;
  left: 27%;
  transform: translate(0%, -8%);
  background: #fff;
  width: 55%;
  height: 620px;
  border: 2px solid #000;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 1rem;
  overflow: auto;
  border-radius: 8px;
}

.EntryDelete_2 {
  position: absolute;
  top: 20%;
  left: 27%;
  transform: translate(0%, -8%);
  background: #fff;
  width: 55%;
  height: 400px;
  border: 2px solid #000;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 1rem;
  overflow: auto;
  border-radius: 8px;
}

.EntryDelete_3 {
  position: absolute;
  top: 20%;
  left: 32%;
  transform: translate(0%, -8%);
  background: #fff;
  width: 40%;
  height: 480px;
  border: 2px solid #000;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 1rem;
  overflow: auto;
  border-radius: 8px;
}
