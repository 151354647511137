.CustomInstallment {
  background: #fff;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;

  .container {
    display: flex;
    flex-direction: column;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
  }
  .warning {
    color: rgb(56, 121, 218);
    font-size: 10px;
  }
  .MuiTableCell-head {
    font-weight: 600;
  }
  .buttonRow {
    margin: auto 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }

  .username {
    margin: 38px 0 25px !important;
  }

  .amount {
    margin: 38px 0 32px !important;
  }

  .enteramount {
    width: 50% !important;
    margin-right: 18px !important;
  }

  .curr {
    width: 50% !important;
  }

  .cancel {
    border-radius: 8px;
    background: #eb3323;
    color: white;
    width: 50% !important;
    padding: 12px 0 10px !important;
    margin-right: 18px !important;
  }

  .cancel:hover {
    cursor: pointer;
  }

  .generate {
    border-radius: 8px;
    background: #1876d2;
    color: white;
    width: 50% !important;
    padding: 12px 0 10px !important;
  }

  .generate:hover {
    cursor: pointer;
  }
}

.CustomInstallment_copylink {
  background: #fff;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;

  .container {
    display: flex;
    flex-direction: column;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
  }
  .warning {
    color: rgb(56, 121, 218);
    font-size: 10px;
  }
  .MuiTableCell-head {
    font-weight: 600;
  }
  .buttonRow {
    margin: auto 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }

  .username {
    margin: 38px 0 25px !important;
  }

  .amount {
    margin: 38px 0 32px !important;
  }

  .enteramount {
    width: 50% !important;
    margin-right: 18px !important;
  }

  .curr {
    width: 50% !important;
  }

  .cancel {
    border-radius: 8px;
    background: #eb3323;
    color: white;
    width: 50% !important;
    padding: 12px 0 10px !important;
    margin-right: 18px !important;
  }

  .cancel:hover {
    cursor: pointer;
  }

  .generate {
    border-radius: 8px;
    background: #1876d2;
    color: white;
    width: 50% !important;
    padding: 12px 0 10px !important;
  }

  .generate:hover {
    cursor: pointer;
  }
}
