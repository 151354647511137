$tiny: 300px;
$small: 500px;
$medium: 800px;
$large: 1000px;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

.edit-fee {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 32px;
  @media only screen and (max-width: $large) {
    padding: 8px;
  }
  background: white;
  font-family: "Source Sans Pro";
  align-items: center;
  height: -webkit-fill-available;

  .search-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    .input-box {
      .input-label {
        color: #3c4852;
        opacity: 0.8;
      }

      .input-field {
        border: 1px solid rgba(33, 42, 57, 0.25);
        border-radius: 8px;
        padding: 4px 8px;
        color: #3c4852;
        opacity: 0.9;
        min-width: 200px;
        width: 100%;
        height: 58px;
        font-size: 18px;
      }
    }

    .edit-fee-btn,
    .input-box {
      margin: 32px;

      @media only screen and (max-width: $large) {
        margin: 32px 16px;
      }

      @media only screen and (max-width: $medium) {
        margin: 16px 8px;
        width: 100%;
      }
    }

    .edit-fee-btn {
      height: 56px;
    }
  }

  .application-info {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    margin: 0px 64px;

    @media only screen and (max-width: $large) {
      margin: 0px 32px;
    }

    @media only screen and (max-width: $medium) {
      margin: 0px 8px;
    }

    .info-block {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .info-label {
        min-width: 150px;
        color: #3c4852;
        opacity: 0.9;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
      }

      .info-data {
        color: #3c4852;
        opacity: 0.9;
        font-weight: 300;
        font-size: 18px;
        line-height: 36px;
      }

      .input-field {
        border: none;
        border-bottom: 1.5px solid rgba(33, 42, 57, 0.65);
        padding: 4px 8px;
        color: #3c4852;
        opacity: 0.9;
        margin-bottom: 16px;
      }

      .input-field2 {
        border: 1px solid rgba(33, 42, 57, 0.25);
        border-radius: 8px;
        padding: 4px 8px;
        color: #3c4852;
        opacity: 0.9;
        margin-bottom: 16px;
      }

      .error-msg {
        color: rgba(255, 0, 0, 0.8);
        display: contents;
      }

      .input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .edit-fee-btn {
    color: white;
    background: #035642;
    border-radius: 8px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    height: fit-content;
    padding: 8px 24px;
    margin: 16px 0px;

    &:hover {
      background: #023a2d;
    }
  }

  .edit-fee-btn-2 {
    color: #035642;
    background: transparent;
    border-radius: 8px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    height: fit-content;

    &:hover {
      color: #023a2d;
    }
  }

  .disabled-btn {
    background: rgb(128, 128, 128);

    &:hover {
      background: rgb(100, 100, 100);
    }
  }
}
