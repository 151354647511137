.addOrEditKaching {
  background: #fff;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;
  .container-kcv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
  }

  .cancel-kcv {
    border-radius: 8px;
    background: #eb3323;
    color: white;
    width: 140px;
    padding: 12px 0 10px !important;
    margin-top: 20px;
  }

  .cancel {
    border-radius: 8px;
    background: #eb3323;
    color: white;
    width: 50% !important;
    padding: 12px 0 10px !important;
  }

  .cancel:hover {
    cursor: pointer;
  }

  .btn-kcv {
    border-radius: 8px;
    background: #055646;
    color: white;
    width: 140px;
    padding: 12px 0 10px !important;
    margin-top: 20px;
  }
  .kcv_dateInput {
    width: 180px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgba(60, 72, 82, 0.35);
    height: 56px !important;
  }
}
