.pendingOrfailedKaching {
  background: #fff;
  min-height: 50vh;
  max-height: 95vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  gap: 1rem;
  overflow: auto;

  .container-kcv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
  }

  .cancel-kcv {
    border-radius: 8px;
    background: #eb3323;
    color: white;
    width: 200px;
    padding: 12px 0 10px !important;
    margin-top: 20px;
  }

  .cancel:hover {
    cursor: pointer;
  }
}
