.sideheaderleadmain {
}

body {
  overflow: scroll !important;
}

.page-item.active .page-link {
  background-color: #097252 !important;
  border-color: #097252 !important;
  color: #fff !important;
}

.page-link {
  color: #097252 !important;
}

.sideheaderleadparacontainer {
  background: #022a22;
}

.sideheaderleadbackbtn {
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  padding-left: 16px;
  line-height: 26px;
  padding-bottom: 16px;
}

.sideheaderleadpara {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  padding: 16px 0px 16px 16px;
  border-left: 4px solid #ffff;
}

.sideheaderleaddropdowncontainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 16px 20px 16px !important;
  background: rgba(2, 42, 34, 0.5);
  padding: 8px;
}

.sideheaderleaddropdown {
  height: 38px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
  color: #ffff;
}

.revenuemaincontainer {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.revenuecontainer {
  width: 280px;
  height: 122px;
  border-radius: 16px;
  border: 1px solid rgba(3, 86, 66, 0.5);
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(3, 86, 66, 0.25);
  padding: 16px 24px;
}

.revenuecontainer h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #3c4852;
  letter-spacing: -0.304px;
}

.revenuecontainer h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.76px;
  color: #097252;
}

.revenueeaddeditbtn {
  width: 155px !important;
  height: 56px !important;
  border-radius: 4px !important;
  background: #035642 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
}

.revenueexportbtn {
  width: 155px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid #035642;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #035642;
  display: flex;
  justify-content: center;
  align-items: center;
}

.revenuebtncontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.groupmaincontainer {
  margin-top: 36px;
}

.groupmainheadingdiv h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #097252;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(60, 72, 82, 0.25);
}

.groupmaintablediv {
  margin-top: 24px;
}

.groupleadtable {
  border: 1px solid rgba(60, 72, 82, 0.25);
  /* border-radius: 14px; */
  background: rgba(60, 72, 82, 0.08);
  height: 58px;
}

.groupleadtable > thead > tr > th {
  vertical-align: middle;
  border-bottom: none;
  border-right: 1px solid rgba(60, 72, 82, 0.25);
  padding: 0px 24px;
  text-align: center;
}

.timetablecontainer {
  display: flex;
  flex-direction: column;
}

.timetablecontainer p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 8px;
}

.timetablemaincontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-end;
}

.timeleadtabledrop {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 200px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #3c4852;
  padding: 8px;
}

.timetabletimecontainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.leadmodelheaderheading {
  text-align: initial;
  color: #212a39;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.leadmodelheaderheading > .btn-close {
  display: none;
}

.addprogram2container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 16px;
  text-align: initial;
  gap: 16px;
}

.addprogramheading {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #212a39;
  padding-bottom: 8px;
  line-height: 16px;
}

.addprograminput {
  width: 598px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.25);
  padding: 14px;
}

.leaddistributionmodel {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  text-align: center !important;
  align-items: center !important;
}

.leaddistributionmodel > div {
  width: 1100px !important;
}

.AdvisorSalesAndSubSalesGroupAssign > div {
  width: 350px !important;
}

.leaddistributionmodel > .modal-lg {
  max-width: 1100px !important;
}

.addprogramcancelbtn {
  width: 74px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #e56974;
}

.addprogramsubmitbtn {
  width: 74px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #407473;
}

.addprogrambtns {
  height: 64px;
  display: flex;
  justify-content: end;
  align-items: center;
  border-top: 0.5px solid rgba(33, 42, 57, 0.25);
  gap: 16px;
  padding-right: 16px;
}

.addAdvisorheading {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #3c4852;

  padding-bottom: 36px;
}

.addadvisorinput {
  width: 314px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.25);
  padding: 14px;
}

.addsinlgeadvisorbtn {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 316px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #035642;
}

.adddvisormaincontainer {
  padding: 30px 16px;
  display: flex;
}

.addadvisorsinglecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  border-right: 1px solid rgba(60, 72, 82, 0.5);
  padding-right: 16px;
}

.addsingleadvisor2container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 38px;
}

.addadvisormultiplecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  padding-left: 16px;
}

.addAdvisorheadingspan {
  padding: 0px 8px;
  border-radius: 100%;
  background: #9da3a8;
  color: #fff;
}

.addadvisormultiplefilebtn {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  width: 316px;
  height: 48px;
  border-radius: 4px;
  background: #28a745;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.addadvisorfileinput {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #3c4852;
}

.addadmissiontargetmonth {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #3c4852;
  padding-bottom: 8px;
}

.addadmissiontargetmonthdropdown {
  width: 200px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.25);
  padding: 8px;
}

.addadmissiontargettablemain {
  width: 1065px;
  height: 530px;
  margin-top: 16px;
}

.freezBtn {
  width: 134px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dc3545;
  color: #dc3545;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  background: #ffff;
  margin: 14px 0px;
}

.rs-anim-fade {
  z-index: 9999;
}

.removeTargetBtn {
  width: 130px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #e56974;
}

.resetFilterButton {
  width: 155px;
  height: 41px;
  border-radius: 4px;
  border: 1px solid #035642;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #035642;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.tooltipl {
  position: relative;
  display: inline-block;
}

.tooltipl .tooltiptextl {
  visibility: hidden;
  width: 500px;
  background-color: grey;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  line-height: 24px;
}

.tooltipl .tooltiptextl2 {
  visibility: hidden;
  width: 200px;
  background-color: grey;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  line-height: 24px;
}

.tooltipl:hover .tooltiptextl {
  visibility: visible;
}

.tooltipl:hover .tooltiptextl2 {
  visibility: visible;
}

.ld_table_center > th {
  text-align: center;
}

.check_box {
  height: 24px;
  width: 24px;
}

.floater_edit {
  height: 62px;
  background: #3c4852;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 16px 28px 16px 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 80vw;
  bottom: 10px;
  border-radius: 8px;
}

.floater_edit p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.floater_button {
  min-width: 152px;
  padding: 0px 14px;
  height: 48px;
  background: #ffffff;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floater_button p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #035642;
}
