body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: sans-serif !important;
  background-color: #eee !important;
}
.page-header {
  padding: 0 !important;
  background: #001529 !important;
  min-height: 50px !important;
  color: #fff !important;
}
.mt_f {
  font-family: "Archivo Black", sans-serif !important;
}
::selection {
  background: #1890ff !important;
}
/*--------Antd-----------*/
html {
  --antd-wave-shadow-color: #055646 !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-btn-primary {
  background-color: #055646 !important;
}
.ant-btn-primary {
  font-family: "Archivo Black", sans-serif !important;
  background-color: #055646 !important;
  border-color: #055646 !important;
}
.ant-back-top-content {
  background: rgba(24, 144, 255, 0.7) !important;
}
.ant-back-top-content:hover {
  background: rgba(24, 144, 255, 1) !important;
}
.ant-calendar .ant-calendar-ok-btn {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
  color: #ffffff !important;
}
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  background: #1890ff !important;
}
.ant-calendar-today .ant-calendar-date {
  color: #000000 !important;
  border-color: #1890ff !important;
}
/*----------------------*/

/* promo main page */
.promocode-home-card {
  font-family: "Archivo Black", sans-serif !important;
  border-radius: 10px !important;
}

.promocode-home-card .ant-card-head {
  font-size: 20px;
  border-radius: 10px 10px 0 0 !important;
  background: #055646 !important;
  color: #fff !important;
}
/*-------------------*/

/*----spaceial promocode----*/
.specialpromocode-main-container {
  height: 0px !important;
}
.promocode-head-elem-container {
  display: flex !important;
}
.promocode-head-elem-container .remove-icon {
  position: absolute !important;
  right: 20px !important;
  cursor: pointer !important;
}
.add-promocode-btn {
  background: #055646 !important;
  color: #ffffff !important;
  font-size: 20px !important;
  margin: 0 20% !important;
  font-family: "Archivo Black", sans-serif !important;
  text-align: center !important;
  height: 70px !important;
  width: 60% !important;
}

.add-promocode-btn:hover {
  background: #ffffff !important;
  color: #055646 !important;
  border: 0 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.Expired {
  text-decoration: line-through !important;
  color: #fff !important;
}
.Expired span {
  background: rgba(0, 0, 0, 0.3) !important;
}
.specialpromocode-text {
  font-size: 25px !important;
  font-weight: bold !important;
}
.specialpromo-course-name {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 400px !important;
}
.specialpromocode-text span {
  /* background: #fc8ca8; */
  background: #fc8ca8 !important;
  color: #fff !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 10px !important;
  border-style: dashed !important;
  border-width: thin !important;
  cursor: -webkit-grab !important;
  cursor: grab !important;
}
.content-contriner {
  padding: 24px !important;
  margin: 0 !important;
  min-height: 400px !important;
}
.CountDownBadge {
  margin: 10px !important;
  width: 30% !important;
  background-color: #055646 !important;
  border-radius: 15px !important;
}

.CountDownBadge span {
  color: #ffffff !important;
}

.special-promocode-detail {
  font-size: 20px !important;
  margin: 5px !important;
  font-family: "Poiret One", cursive !important;
}
.show-specialpromocode-container {
  background: #fff !important;
  padding: 50px !important;
  min-height: 400px !important;
}
.show-specialpromocode-container .just-created {
  max-width: 600px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}
.promos {
  position: relative !important;
}
.special-promocode-outer-container {
  border: 0 !important;
  height: auto !important;
  position: relative !important;
}
.special-promocode-container {
  padding-left: 5%;
  padding-right: 5% !important;
  width: 100% !important;
}
.special-promocode-container .ant-card-head {
  height: 74px !important;
}
.special-promocode-show-card {
  border-radius: 30px !important;
  margin-top: 50px !important;
  height: 380px !important;
  border: 0 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.02) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.special-promocode-show-card:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.special-promocode-show-card .ant-card-head {
  border-radius: 30px 30px 0px 0px !important;
  background: #055646 !important;
  color: #fff !important;
}

.special-promocode-show-card .ant-card-body {
  border-radius: 0px 0px 30px 30px !important;
  font-family: "Amiri", serif !important;
}

.special-promocode-show-card .ant-card-head-title {
  font-family: "Archivo Black", sans-serif !important;
  font-size: 20px !important;
}

.center {
  display: block !important;
  text-align: -moz-center !important;
  text-align: -webkit-center !important;
}

/* APPLICATION REVIEW DASHBOARD */

.no-margin {
  margin: 0px !important;
}
.no-padding {
  padding: 0px !important;
}

.ar-header {
  background-color: #055646;
  height: 65px;
  border-bottom: 2px solid rgb(243, 235, 235);
  /* box-shadow: 0 0 16px 1.4px #ffffff; */
  color: #ffffff;
  font-size: 22px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  padding: 18px;
}

.filter-nm {
  font-weight: 600;
  font-size: 20px;
  margin: 24px 0px;
  font-family: "Source Sans Pro", sans-serif;
}

.app-review-sidebar .ant-select-selection {
  background-color: transparent !important;
  border: none;
  color: white;
}

.app-review-sidebar .ant-select-arrow .ant-select-arrow-icon svg {
  color: white !important;
}

.app-review-sidebar .ant-select-selection:focus {
  background-color: transparent !important;
  border: none;
  outline: none;
  color: white;
}

.app-dropdown {
  background-color: transparent;
  border-bottom: 2px solid white;
}

.app-review {
  padding: 0px 40px;
  margin-bottom: 70px;
  overflow-x: auto;
}

.app-review .table {
  margin: 32px 0px;
  width: 100%;
}

.app-review .table-container {
  table-layout: fixed;
  background: #fff;
  color: #055646;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-top: 0.5px solid #000;
  padding: 0 !important;
}

.app-review .ar-table-header {
  height: 56px;
  font-size: 16px;
  color: #fff;
  background: #055646;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.app-review table td {
  height: fit-content;
  border-right: 2px solid rgb(171, 185, 185, 0.2);
}
.app-review .container-td {
  width: 5%;
  word-break: break-word;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.app-review .cf-row1 {
  background: #fff;
}

.app-review .cf-row0 {
  background: #cfd8dc;
}

.ar-sidebar {
  background-color: #055646;
  color: #f7f4f4f5;
  padding: 32px !important;
  min-height: calc(100vh - 60px);
  /* width: 250px; */
}

.ar-show-more {
  background-color: #055646;
  border-radius: 5px;
  width: 100px;
  margin-top: 20px;
  border: none;
  /* margin-left: 20px; */
  float: right;
  padding: 12px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
  transition: all 0.6s;
}

.ar-show-more:hover {
  opacity: 0.9;
}

.ar-back-button {
  background-color: #055646;
  border-radius: 5px;
  width: 100%;
  padding: 13px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
  transition: all 0.6s;
}

.ar-back-button:hover {
  opacity: 0.9;
}

.ar-vw-app {
  background-color: #055646;
  border-radius: 5px;
  width: 100%;
  height: fit-content;
  padding: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border: none;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.6s;
}

.ar-norecord {
  border: 2px bold #000;
  text-align: center;
  color: red;
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 0 0 16px 1.4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  margin: 70px;
  padding: 15px;
}

.ar-total-records {
  color: #055646;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 600;
}

.show-sm {
  display: none;
}

.visible-loader {
  background: url(https://cdn.pegasus.imarticus.org/images/imarticus-logo-loader-med.svg)
    center no-repeat;
  display: inline-block;
  font-size: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12px);
}

.loading-icon {
  background: url(https://cdn.pegasus.imarticus.org/images/imarticus-logo-loader-med.svg)
    center no-repeat;
  background-size: 60px;
  display: inline-block;
  font-size: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  /* z-index: 999; */
}

.loading-rotate {
  background: url(https://cdn.eckovation.com/images/Imarticus_Loader_Circle-02-1.svg)
    center no-repeat;
  background-size: 100px;
  -webkit-animation: spin 0.6s linear infinite;
  -moz-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
  display: inline-block;
  font-size: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  /* z-index: 999; */
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.alert-style {
  width: 285px;
  padding: 12px;
  height: max-content;
  position: fixed;
  z-index: 1700;
  bottom: 30px;
  left: 24px;
  display: flex;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.ar-modal-main-head {
  margin: 24px 0px;
  color: #055646;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
}

.ar-modal-head {
  color: #055646;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  opacity: 0.75;
  text-align: left;
}

.ar-modal-data {
  margin-bottom: 24px;
  padding: 13px;
  background-color: rgb(225, 238, 243);
  border-radius: 8px;
  min-height: 48px;
  color: #055646;
  font-size: 14px;
  font-weight: 600;
  white-space: pre-line;
}

.ar-modal-text-input {
  background: #ffffff;
  border: 1px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 18px;
}

.ar-modal-text-input {
  background: #ffffff;
  border: 1px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 18px;
}

.ar-modal-text-input {
  background: #ffffff;
  border: 1px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 18px;
}
.ar-padding-left {
  padding: 0px;
  padding-left: 15px;
}

.ar-padding-right {
  padding: 0px;
  padding-right: 15px;
}

.applicationmodal .ant-modal-footer {
  display: none;
}

.ar-customised-textarea {
  background-color: #ffffff;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  /* box-shadow: 0 0 3px 0 rgba(0, 157, 220, 0.45), inset 0 2px 3px 0 rgba(0, 157, 220, 0.3); */
  width: 100%;
  color: #212a39;
  font-size: 16px;
  padding: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.approved-clr {
  background-color: rgba(48, 185, 6, 0.05);
  border: 1px solid #30b906;
  border-radius: 5px;
  /* max-width: 115px; */
  /* height: 32px; */
  color: #2fb805;
  font-size: 18px;
  font-weight: 600;
  padding: 6px 24px;
  text-align: center;
  float: right;
}

.reject-clr {
  background-color: rgba(233, 75, 53, 0.15);
  border: 1px solid #e94b35;
  border-radius: 5px;
  /* max-width: 115px; */
  /* height: 32px; */
  color: #e94b35;
  font-size: 18px;
  font-weight: 600;
  padding: 6px 24px;
  text-align: center;
  float: right;
}

.pending-clr {
  background-color: rgba(245, 166, 35, 0.05);
  border: 1px solid #f5a623;
  border-radius: 5px;
  /* max-width: 115px; */
  /* height: 32px; */
  color: #f5a523;
  font-size: 18px;
  font-weight: 600;
  padding: 6px 24px;
  text-align: center;
  float: right;
}

.shortlist-clr {
  background-color: rgba(245, 166, 35, 0.05);
  border: 1px solid rgb(235, 161, 66);
  border-radius: 5px;
  color: rgb(235, 161, 66);
  font-size: 18px;
  font-weight: 600;
  padding: 6px 24px;
  text-align: center;
  float: right;
}

.crs-box {
  background-color: rgba(42, 105, 192, 0.05);
  border: 1px solid #2a69c0;
  border-radius: 4px;
  color: #2968bf;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 600;
}
.sub_date {
  margin-top: 8px;
  float: right;
}

.applicationmodal .ant-modal-body {
  padding: 0px !important;
}

.ar-sm-table-div {
  margin: 8px 0px;
  background-color: #fff;
  border: 2px solid rgb(240, 234, 234);
  border-radius: 4px;
}

.ar-border-desgn {
  border-radius: 4px;
  background-image: linear-gradient(
    134.72deg,
    rgb(10, 126, 194) 0,
    #055646 100%
  );
  height: 100%;
  width: 6%;
}

.ar-sm-u-name {
  color: #055646;
  font-size: 16px;
  font-weight: 600;
}

.ar-sm-crs-name {
  color: #055646;
  font-size: 14px;
  margin: 8px 0px;
}

.ar-sm-sub-tim {
  color: #055646;
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 8px;
}

.pr-padding-main-cont {
  padding: 30px;
}

.tooltip_link {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 4px;
}

.tooltip_link:hover {
  background-color: #e9e7e7;
}

.btn-reject-cand {
  border: 1px solid #e94b35;
  border-radius: 5px;
  color: #e94b35;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  width: 184px;
  height: 48px;
  transition: all 0.5s;
  cursor: pointer;
}

.btn-reject-cand:hover {
  opacity: 0.7;
}

.btn-shortlist-cand {
  border: 1px solid #255ca8;
  border-radius: 5px;
  background: transparent;
  width: 184px;
  height: 48px;
  color: #255ca8;
  font-size: 16px;
  font-weight: 600;
  float: right;
  line-height: 19px;
  transition: all 0.5s;
  cursor: pointer;
}

.btn-shortlist-cand:hover {
  opacity: 0.7;
}

.btn-approve-cand {
  border: none;
  background-color: #255ca8;
  border-radius: 4px;
  width: 184px;
  height: 48px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  float: right;
  line-height: 19px;
  transition: all 0.5s;
  cursor: pointer;
}

.btn-approve-cand:hover {
  opacity: 0.7;
}

.btn-revert-cand {
  border: none;
  background-color: #255ca8;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  float: right;
  line-height: 19px;
  transition: all 0.5s;
  cursor: pointer;
}

.btn-revert-cand:hover {
  opacity: 0.7;
}

.applicationmodal .ant-modal-title {
  font-weight: 600 !important;
  color: #055646 !important;
  font-size: 18px;
}

@media screen and (max-width: 992px) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: block;
  }
  .ar-sidebar {
    height: auto;
    padding: 24px !important;
    min-height: auto;
  }
}

@media screen and (max-width: 768px) {
  .ar-padding-left {
    padding: 0px !important;
  }

  .ar-padding-right {
    padding: 0px !important;
  }
  .sub_date {
    margin-top: 8px;
    float: left;
  }

  .pending-clr {
    font-size: 16px;
    width: 100%;
    float: left;
  }
  .approved-clr {
    font-size: 16px;
    width: 100%;
    float: left;
  }
  .shortlist-clr {
    font-size: 16px;
    width: 100%;
    float: left;
  }
  .reject-clr {
    font-size: 16px;
    width: 100%;
    float: left;
  }

  .ar-total-records {
    color: #055646;
    margin-top: 32px;
    font-size: 18px;
    font-weight: 600;
  }

  .prmcd-btn {
    white-space: pre-line;
    height: auto;
    font-size: 10px;
    font-weight: 400 !important;
  }

  .pr-padding-main-cont .ant-card-body {
    padding: 12px;
  }
  /* .sm-sc-sidebar{
    width: 100%;
    height: 300px;
  } */

  .pr-padding-main-cont {
    padding: 30px 12px;
  }

  .btn-approve-cand {
    margin-top: 24px;
    width: 100%;
    float: none;
  }

  .btn-revert-cand {
    margin-top: 24px;
    width: 100%;
    float: none;
  }

  .btn-shortlist-cand {
    margin-top: 24px;
    width: 100%;
    float: none;
  }

  .btn-reject-cand {
    margin-top: 24px;
    width: 100%;
  }
}

.error-popover {
  width: 285px;
  padding: 12px;
  height: max-content;
  position: fixed;
  background: #fff;
  z-index: 1700;
  bottom: 30px;
  left: 24px;
  display: flex;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  border: 0.5px solid rgb(231, 231, 231);
  box-shadow: 0 3px 10px 0px rgba(109, 121, 190, 0.315);
  border-radius: 4px;
}
@media only screen and (max-width: 768px) {
  .error-popover {
    margin-left: -12px;
    margin-bottom: 35px;
  }
}
.error-popover .close {
  width: 20px;
}
.error-popover .ep-icon-container {
  width: 30px;
}
.error-popover .ep-content {
  width: calc(100% - 50px);
}
.error-popover .ep-msg {
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  align-items: center;
  margin: 0;
}
.error-popover .ep-heading {
  margin: 0;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  color: #000;
  font-weight: 600;
}
.error-popover .close-img {
  width: 10px;
  cursor: pointer;
  height: 10px;
}
.error-popover .icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
}
.hide-error-popover {
  margin-left: calc(-285px - 24px);
}

.app-review-sidebar .MuiAutocomplete-root {
  background: #055646;
  margin: 60px auto;
  min-width: 95%;
}

.app-review-sidebar .MuiInputLabel-root,
.app-review-sidebar .MuiSvgIcon-root,
.app-review-sidebar .MuiInputBase-root {
  color: white !important;
}

.app-review-sidebar .MuiInputLabel-root.Mui-focused,
.app-review-sidebar .MuiInputBase-root.Mui-focused,
.app-review-sidebar .MuiOutlinedInput-notchedOutline.Mui-focused {
  color: white !important;
}

.app-review-sidebar .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.app-review-sidebar .MuiOutlinedInput-notchedOutline.Mui-focused {
  border-color: white !important;
}

.app-review-sidebar .MuiInputLabel-root.Mui-disabled,
.app-review-sidebar .Mui-disabled .MuiSvgIcon-root,
.app-review-sidebar .MuiInputBase-root.Mui-disabled {
  color: rgb(165, 165, 165) !important;
}

.app-review-sidebar .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: rgb(165, 165, 165) !important;
}

.ar-header-bar {
  display: flex;
  justify-content: space-between;
}

.ar-header-bar-course {
  display: flex;
  justify-content: center;
}

.paid {
  color: green;
}

.unpaid {
  color: red;
}

.partial-paid {
  color: green;
}

.visible-loader2 {
  display: block;
  font-size: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  /* background-color: rgba(255, 255, 255, 0.6); */
}
.loading-icon2 {
  /* background: url(https://cdn.pegasus.imarticus.org/imarticus_2/imarticus_logo_loader.svg) */
  /* center no-repeat; */
  display: block;
  font-size: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
}

.rotate360 {
  animation: rotate 0.6s linear infinite;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
